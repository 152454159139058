import React, { useState, useEffect } from "react";
import { Box, Tab, Tabs, Button, LinearProgress } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";

import TabPDFDraft from "./TabPDFDraft";
import TabPDFDetail from "./TabPDFDetail";

import { API_get_bookings_id } from "../../utils/http";
import OperDetail from "../operating/OperDetail";
import WebsiteViewer from "../WebsiteViewer";
import { PDFDownloadLink } from "@react-pdf/renderer";

import DocumentDetail from "./DocumentDetail";
import DocumentDraft from "./DocumentDraft";

const base_url_website = "https://booking.svietnamadventures.com";

export default function OutputRight({ booking_id }) {
  const [value, setValue] = useState(0);
  const [data_pdf, set_data] = useState(null);
  const [loading, set_loading] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const get_data_booking = async () => {
    await API_get_bookings_id(booking_id, (x) => {
      if (x.success) {
        set_data(x.data);
        set_loading(false);
      }
    });
  };

  useEffect(() => {
    get_data_booking();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "r") {
        event.preventDefault();
        get_data_booking();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  if (loading) {
    return (
      <Box sx={{ width: "100%", marginTop: 10 }}>
        <LinearProgress />
      </Box>
    );
  } else {
    return (
      <Box style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Draft booking" />
          <Tab label="Detail Itinerary" />
          <Tab label="Price list" />

          <Tab label="Website draft" />
          <Tab label="Website detail" />
        </Tabs>
        <Box style={{ flex: 1, overflow: "auto" }}>
          {value == 0 ? (
            <Box
              sx={{ display: "flex", height: "100%", flexDirection: "column" }}
            >
              <Button
                style={{ position: "absolute", bottom: 100, right: 30 }}
                variant="contained"
                endIcon={<CachedIcon />}
                onClick={() => {
                  get_data_booking();
                }}
              >
                Re-generate pdf file
              </Button>
              <Box style={{ margin: 10 }}>
                <PDFDownloadLink
                  document={<DocumentDraft data_pdf={data_pdf} />}
                  fileName={
                    "Booking confirmation-" + data_pdf.booking_number + ".pdf"
                  }
                >
                  {({ blob, url, loading, error }) =>
                    loading
                      ? "Loading document..."
                      : "Download Booking confirmation pdf "
                  }
                </PDFDownloadLink>
              </Box>

              <TabPDFDraft data_pdf={data_pdf} booking_id={booking_id} />
            </Box>
          ) : null}
          {value == 1 ? (
            <Box
              sx={{ display: "flex", height: "100%", flexDirection: "column" }}
            >
              <Button
                style={{ position: "absolute", bottom: 100, right: 30 }}
                variant="contained"
                endIcon={<CachedIcon />}
                onClick={() => {
                  get_data_booking();
                }}
              >
                Re-generate pdf file
              </Button>
              <Box style={{ margin: 10 }}>
                <PDFDownloadLink
                  document={<DocumentDetail data_pdf={data_pdf} />}
                  fileName={
                    "Detail itinerary-" + data_pdf.booking_number + ".pdf"
                  }
                >
                  {({ blob, url, loading, error }) =>
                    loading
                      ? "Loading document..."
                      : "Download detail itinerary pdf "
                  }
                </PDFDownloadLink>
              </Box>
              <TabPDFDetail data_pdf={data_pdf} booking_id={booking_id} />
            </Box>
          ) : null}

          {value == 2 ? (
            <Box sx={{ height: "100%" }}>
              <OperDetail booking_id={booking_id} />
            </Box>
          ) : null}

          {value == 3 ? (
            <Box sx={{ height: "100%" }}>
              <WebsiteViewer
                link={
                  base_url_website +
                  "/booking/draft/" +
                  booking_id +
                  "_" +
                  data_pdf.secret
                }
              />
            </Box>
          ) : null}

          {value == 4 ? (
            <Box sx={{ height: "100%" }}>
              <WebsiteViewer
                link={
                  base_url_website +
                  "/booking/detail/" +
                  booking_id +
                  "_" +
                  data_pdf.secret
                }
              />
            </Box>
          ) : null}
        </Box>
      </Box>
    );
  }
}
