import React from "react";
import { Document, Page, Text } from "@react-pdf/renderer";

import { styles } from "./AppStyle";
import {
  HeadingWithColor,
  Detail2Column,
  TableRowDetail,
  TableHeaderDetail,
  PriceDetail,
  PriceList,
  InfoCompany,
  TableHeaderDetailAcc,
  TableRowDetailAcc,
  TableRowNoteHotel,
  IterDetail,
} from "./TableCom";
import {
  FooterLine,
  HeaderCus,
  FooterLeft,
  FooterRight,
  FooterPageNum,
} from "./HeaderFooter";
import { gen_day } from "../../utils/dayshow";

export default function DocumentDetail({ data_pdf }) {
  if (data_pdf == null) {
    return null;
  } else {
    const day_showing = gen_day(data_pdf.day_start, data_pdf.activities.length);
    const title_pdf = data_pdf.booking_number + ".pdf";

    const case_1 = [
      `During this time (365 days) the amount can be applied to other tours and services.`,
      `After one (1) year the deposit amount is forfeited. Otherwise, you will be charged 20% of the total value of the trip, plus any issued tickets/ evisa, hotels or other service fees that were charged by our partners if you want to get the money back.`,
    ];

    const case_2_1 = [
      `We will apply 10% charge of the total value of the trip, plus any issued tickets/ evisa, hotels or other service fees that was charged from our partners. Once you have your travel date, we will reconfirm the package price again since it may be changed, please check the date with us before making any arrangement.`,
    ];
    const case_2 = [
      `More than 30 days prior to departure: 20% charge per total package price, plus any issued tickets/ evisa, hotels or other service fees that were charged by our partners`,
      `25-30 days prior to departure: 50% of the total value of the trip plus any issued tickets, and evisa`,
      `Within 1-25 Days prior to departure: 100% on total charge.`,
      `No-Show: 100% on total charge.`,
    ];

    return (
      <Document title={title_pdf}>
        <Page style={styles.body}>
          <HeaderCus />
          <Text style={styles.title}>{data_pdf.detail_title_pdf}</Text>
          <HeadingWithColor label="BOOKED BY" />
          <Detail2Column label="Prepared by" value={data_pdf.prepared_by} />
          <Detail2Column
            label="Specially designed for"
            value={data_pdf.customer_name}
          />
          <Detail2Column label="Cell/Whatsapp" value={data_pdf.customer_cell} />
          <Detail2Column label="Email" value={data_pdf.customer_email} />
          <HeadingWithColor label="BOOKING DETAILS" />
          <Detail2Column label="Booking REF" value={data_pdf.booking_number} />
          <Detail2Column label="No of clients" value={data_pdf.no_of_client} />
          <Detail2Column label="Check in" value={data_pdf.check_in} />
          <Detail2Column label="Check out" value={data_pdf.check_out} />
          <Detail2Column label="Package" value={data_pdf.package} />
          <Detail2Column label="Room type" value={data_pdf.room_type} />
          <Detail2Column label="Tour type" value={data_pdf.tour_type} />
          <Detail2Column
            label="Arrival Airport"
            value={data_pdf.arrival_airport}
          />
          <Detail2Column
            label="Departure Airport"
            value={data_pdf.departure_airport}
          />
          <Detail2Column
            label="Arrival flight"
            value={data_pdf.arrival_flight}
          />
          <Detail2Column
            label="Departure flight"
            value={data_pdf.departure_flight}
          />
          <Detail2Column label="Tour guide" value={data_pdf.tour_guide} />
          <Detail2Column
            label="Special requirements"
            value={data_pdf.special_food_requirements}
          />
          <HeadingWithColor label="BRIEF ITINERARY" />
          <TableHeaderDetail />
          {data_pdf.activities.map((item, index) => (
            <TableRowDetail
              key={`activity-${index}`}
              data={item}
              index={index}
              day_showing={day_showing}
            />
          ))}
          <HeadingWithColor label="ACCOMMODATION" />
          <Text
            style={{
              marginLeft: 10,
              marginTop: 3,
              marginBottom: 3,
              fontSize: 11,
              textAlign: "left",
              fontFamily: "Helvetica-Bold",
            }}
          >
            Number and type of room: {data_pdf.room_type}
          </Text>
          <TableHeaderDetailAcc />
          {data_pdf.accommondation.map((item, index) => (
            <TableRowDetailAcc
              key={`accommodation-${index}`}
              data={item}
              index={index}
            />
          ))}
          <TableRowNoteHotel />
          <HeadingWithColor label="PRICE" />
          {data_pdf.price.map((item, index) => (
            <PriceDetail
              key={`price-${index}`}
              label={item.label}
              value={item.value}
              price={item.price}
            />
          ))}
          <Text style={styles.textBold}>Includes:</Text>
          {data_pdf.price_include.split("\n").map((item, index) => (
            <PriceList key={`price-include-${index}`} label={item} />
          ))}
          <Text style={styles.textBold}>Excludes:</Text>
          {data_pdf.price_exclude.split("\n").map((item, index) => (
            <PriceList key={`price-exclude-${index}`} label={item} />
          ))}
          <HeadingWithColor label="ITINERARY" />
          {data_pdf.activities.map((item, index) => (
            <IterDetail
              key={`itinerary-${index}`}
              label={
                day_showing[index].day_details +
                ": " +
                item.activities
                  .toUpperCase()
                  .replaceAll("\n", " ")
                  .split("(")[0]
              }
              value={item.detail}
            />
          ))}
          <Text style={styles.notetext}>Thank you for travelling with us</Text>
          <Text style={styles.textBold}>REMEMBER TO BRING</Text>
          {data_pdf.remember_to_bring.split("\n").map((item, index) => (
            <PriceList key={`remember-${index}`} label={item} />
          ))}
          <Text style={styles.textBold}>NOTE</Text>
          <Text style={styles.textNote}>{data_pdf.note}</Text>
          <Text style={styles.textBold}>CANCELLATION AND REFUND POLICY</Text>
          <Text style={styles.textCancel}>
            Case 1: The first deposit was made to secure the package price
            {"\n"}
            {" •  "}If you cancel, and choose another date to travel: The
            deposit paid to us will be secured for your other trip valid for one
            (1) year from the date the deposit was received.
          </Text>
          {case_1.map((item, index) => (
            <Text key={`case1-${index}`} style={styles.textCancelLeft}>
              {" •  "}
              {item}
            </Text>
          ))}
          <Text style={styles.textCancel}>
            {" •  "}If you cancel without rescheduling the trip, the first
            deposit is non-refundable.
          </Text>
          <Text style={styles.textCancel}>
            Case 2: The 2nd deposit was made to start to issue your booking
            {"\n"}
            {" •  "}If you cancel, and choose another date:
          </Text>
          {case_2_1.map((item, index) => (
            <Text key={`case2-1-${index}`} style={styles.textCancelLeft}>
              {" •  "}
              {item}
            </Text>
          ))}
          <Text style={styles.textCancel}>
            {" •  "}If you cancel without rescheduling the trip, we will apply:
          </Text>
          {case_2.map((item, index) => (
            <Text key={`case2-${index}`} style={styles.textCancelLeft}>
              {" •  "}
              {item}
            </Text>
          ))}
          <Text style={styles.textCancel}>
            P/S: We highly recommend travel insurance to protect against
            unforeseen circumstances such as illness, weather disruptions, or
            personal emergencies
          </Text>

          <Text style={{ ...styles.textBold, marginBottom: 10 }}>
            S VIETNAM ADVENTURES TRAVEL CO.,LTD
          </Text>
          <InfoCompany label="Tel/ Whatsapp" value=": +84 84 579 4384" />
          <InfoCompany label="Email" value=": info@svietnamadventures.com" />
          <InfoCompany label="Website" value=": www.svietnamadventures.com" />
          <FooterLine />
          <FooterLeft />
          <FooterPageNum />
          <FooterRight />
        </Page>
      </Document>
    );
  }
}
