import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { API_get_vendor_att } from "../utils/http";
import VendorService from "./VendorService";
import Navbar from "./Navbar";

export default function VendorInput(props) {
  let init_data_null = {
    code: "",
    name: "",
    phone: "",
    email: "",
    address: "",
    type: "",
  };
  const [data_vendor_att, set_data_vendor_att] = useState(null);

  // const RESTAURANT = "restaurant";
  const HOTEL = "hotel";
  const TRANS = "transportation";
  const TOUR = "tour";
  const FLIGHT = "flight";
  const CRUISE = "cruise";

  const [init_data, set_init_data] = useState(
    props.init_data == null ? init_data_null : props.init_data
  );

  function get_data_vendor_att() {
    API_get_vendor_att(props.init_data._id, (data_org) => {
      set_data_vendor_att(data_org);
    });
  }

  useEffect(() => {
    get_data_vendor_att();
  }, []);

  return (
    <Box>
      <Navbar
        title={props.init_data == null ? "Create vendor" : "Edit vendor"}
        onGoBack={() => props.onGoBackClick()}
        onSave={() => props.onSaveClick(props.init_data._id, init_data)}
      />
      <Box
        style={{
          flexDirection: "column",
          display: "flex",
          padding: 10,
          justifyContent: "space-between",
        }}
      >
        <Box style={{ flexDirection: "row", display: "flex" }}>
          <Box>
            <FormControl style={{ margin: 10 }}>
              <FormLabel id="demo-radio-buttons-group-label">Type</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={init_data.type}
                onChange={(e) => {
                  set_init_data({ ...init_data, type: e.target.value });
                }}
              >
                <FormControlLabel
                  value={HOTEL}
                  control={<Radio />}
                  label={HOTEL}
                />
                <FormControlLabel
                  value={FLIGHT}
                  control={<Radio />}
                  label={FLIGHT}
                />
                <FormControlLabel
                  value={TOUR}
                  control={<Radio />}
                  label={TOUR}
                />
                <FormControlLabel
                  value={TRANS}
                  control={<Radio />}
                  label={TRANS}
                />
                <FormControlLabel
                  value={CRUISE}
                  control={<Radio />}
                  label={CRUISE}
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box style={{ flexWrap: "wrap", margin: 15 }}>
            <TextField
              style={{ margin: 5, flex: 1, maxWidth: 500 }}
              id="code"
              label="code"
              variant="outlined"
              value={init_data.code}
              onChange={(e) =>
                set_init_data({ ...init_data, code: e.target.value })
              }
            />
            <TextField
              style={{ margin: 5, flex: 1, maxWidth: 500 }}
              id="name"
              label="name"
              variant="outlined"
              value={init_data.name}
              onChange={(e) =>
                set_init_data({ ...init_data, name: e.target.value })
              }
            />
            <TextField
              style={{ margin: 5, flex: 1, maxWidth: 500 }}
              id="User receive email"
              label="User receive email"
              variant="outlined"
              value={init_data.user_receive_email}
              onChange={(e) =>
                set_init_data({
                  ...init_data,
                  user_receive_email: e.target.value,
                })
              }
            />

            <TextField
              style={{ margin: 5, flex: 1, maxWidth: 500 }}
              id="phone"
              label="phone"
              variant="outlined"
              value={init_data.phone}
              onChange={(e) =>
                set_init_data({ ...init_data, phone: e.target.value })
              }
            />
            <TextField
              style={{ margin: 5, flex: 1, maxWidth: 500 }}
              id="email"
              label="email"
              variant="outlined"
              value={init_data.email}
              onChange={(e) =>
                set_init_data({ ...init_data, email: e.target.value })
              }
            />
            <TextField
              style={{ margin: 5, flex: 1, maxWidth: 500 }}
              id="address"
              label="address"
              variant="outlined"
              value={init_data.address}
              onChange={(e) =>
                set_init_data({ ...init_data, address: e.target.value })
              }
            />

            <TextField
              style={{ margin: 5, flex: 1, maxWidth: 500 }}
              id="mail_cc_1"
              label="mail cc 1"
              variant="outlined"
              value={init_data.mail_cc_1}
              onChange={(e) =>
                set_init_data({ ...init_data, mail_cc_1: e.target.value })
              }
            />

            <TextField
              style={{ margin: 5, flex: 1, maxWidth: 500 }}
              id="mail_cc_2"
              label="mail cc 2"
              variant="outlined"
              value={init_data.mail_cc_2}
              onChange={(e) =>
                set_init_data({ ...init_data, mail_cc_2: e.target.value })
              }
            />

            <TextField
              style={{ margin: 5, flex: 1, maxWidth: 500 }}
              id="mail_cc_3"
              label="mail cc 3"
              variant="outlined"
              value={init_data.mail_cc_3}
              onChange={(e) =>
                set_init_data({ ...init_data, mail_cc_3: e.target.value })
              }
            />
          </Box>
        </Box>
        {data_vendor_att == null ? null : (
          <VendorService
            vendorType={init_data.type}
            vendorGroupID={props.init_data._id}
            activ={props.activ}
            accom={props.accom}
            data={data_vendor_att}
            get_data_vendor_att={() => get_data_vendor_att()}
          />
        )}
      </Box>
    </Box>
  );
}
