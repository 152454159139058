import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useTasks } from "../AppContext";
import { API_edit_booking } from "../utils/http";
import { useEffect } from "react";

export default function NavbarSave({ title, onGoBack, booking_id }) {
  const data_pdf = useTasks();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onGoBack();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onGoBack]);

  const SaveChange = () => {
    let data_body = data_pdf;
    for (var key in data_body) {
      if (key.startsWith("_")) {
        delete data_body[key];
      }
    }
    API_edit_booking(booking_id, data_body, (data) => {});
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "s") {
        event.preventDefault();
        SaveChange();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [data_pdf, booking_id]);

  return (
    <AppBar position="sticky" sx={{ top: 0, zIndex: 1100 }}>
      <Toolbar>
        <IconButton onClick={onGoBack} sx={{ color: "white" }}>
          <ArrowBackIcon />
          <Typography sx={{ ml: 1 }}>Exit</Typography>
        </IconButton>

        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>

        <IconButton
          sx={{ color: "white" }}
          onClick={() => SaveChange()}
          color="white"
        >
          <SaveIcon />
          <Typography sx={{ ml: 1 }}>Save</Typography>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
