import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Snackbar, Alert } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

export default function WebsiteViewer({ link }) {
  const iframeRef = useRef(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const reloadIframe = () => {
    if (iframeRef.current) {
      iframeRef.current.src = iframeRef.current.src;
      setSnackbarMessage("Page reloaded");
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "r") {
        event.preventDefault();
        reloadIframe();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box
        style={{
          position: "absolute",
          top: 10,
          right: 30,
          zIndex: 3,
          display: "flex",
          gap: 1,
        }}
      >
        <Button
          style={{ backgroundColor: "white" }}
          variant="outlined"
          startIcon={<RefreshIcon />}
          onClick={reloadIframe}
        >
          Reload
        </Button>
      </Box>
      <Box sx={{ flex: 1, position: "relative", overflow: "hidden" }}>
        <iframe
          ref={iframeRef}
          src={link}
          title="External Website"
          style={{
            border: "none",
            width: "125%",
            height: "125%",
            position: "absolute",
            top: 0,
            left: 0,
            transform: "scale(0.8)",
            transformOrigin: "0 0",
          }}
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        />
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="info"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
