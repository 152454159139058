import React, { useEffect, useState } from "react";
import { Tab, Tabs, Box, LinearProgress } from "@mui/material";

import ListActivities from "./components/ListActivities";
import { API_get_activities_not_hide, API_get_bookings_id } from "./utils/http";
import { useTasks, useTasksDispatch } from "./AppContext";
import InputLeftInfo from "./components/InputLeftInfo";
import InputLeftPrice from "./components/InputLeftPrice";
import ListAccom from "./components/ListAccom";
import { dispatchTYPE } from "./utils/constDispatch";

export default function InputLeft({ booking_id }) {
  const data_pdf = useTasks();
  const dispatch = useTasksDispatch();

  const [accommondation, set_accommondation] = useState(null);
  const [activities, set_activities] = useState(null);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function get_data_activities_accom() {
    API_get_activities_not_hide((data) => {
      set_activities(data.data);
      set_accommondation(data.accom);
    });
  }

  async function get_data() {
    API_get_bookings_id(booking_id, (x) => {
      if (x.success) {
        let data = x.data;
        dispatch({
          type: dispatchTYPE.get_data_pdf,
          value: data,
        });
      }
    });
  }

  useEffect(() => {
    get_data_activities_accom();

    get_data();
  }, []);

  if (accommondation != null && data_pdf != null) {
    return (
      <Box sx={{ height: "100%" }}>
        <Box
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Infomation" />
            <Tab label="Activities" />
            <Tab label="Accommodation" />
            <Tab label="Price" />
          </Tabs>

          <Box style={{ flex: 1, position: "relative", overflow: "hidden" }}>
            <Box
              style={{
                padding: 10,
                height: "100%",
                overflow: "auto",
                paddingBottom: "0px",
              }}
            >
              {value == 0 ? <InputLeftInfo booking_id={booking_id} /> : null}
              {value == 1 ? (
                <ListActivities
                  accommondation={accommondation}
                  activities={activities}
                />
              ) : null}
              {value == 2 ? (
                <ListAccom accommondation={accommondation} />
              ) : null}
              {value == 3 ? <InputLeftPrice /> : null}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box sx={{ width: "100%", marginTop: 10 }}>
        <LinearProgress />
      </Box>
    );
  }
}
