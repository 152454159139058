import React from "react";
import { PDFViewer } from "@react-pdf/renderer";

import DocumentDraft from "./DocumentDraft";

export default function TabPDFDraft({ data_pdf }) {
  if (data_pdf == null) {
    return null;
  } else {
    return (
      <PDFViewer style={{ flex: 1 }}>
        <DocumentDraft data_pdf={data_pdf} />
      </PDFViewer>
    );
  }
}
