import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Parser from "html-react-parser";

import _ from "lodash";
import dayjs from "dayjs";
import EditorCus from "../Editor/Editor";
import { checkOper_or_admin, render_number } from "../../utils/tool";
import { DialogCus } from "../DialogCus";
import EditOper from "./EditOper";
import { API_gg_send_email } from "../../utils/http";

export const VendorItem = ({
  item,
  index,
  booking_details,
  vendor_details,
  DeleteClick,
  ConfirmClick,
  EditOperClick,
  SortByDay,
}) => {
  let status = "unconfirmed";
  let status_count = 0;
  let status_color = "#FFEBEE";

  let sent_color = "#E1F5FE";

  for (let index = 0; index < item.data.length; index++) {
    if (item.data[index].confirm == true) {
      status_count += 1;
    }
  }

  if (status_count == item.data.length) {
    status = "confirmed";
    status_color = "#E1F5FE";
  } else if (status_count < item.data.length && status_count > 0) {
    status = "data changed, confirm again";
    status_color = "#ECEFF1";
  }

  const [open, setOpen] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);

  const [dataEdit, setDataEdit] = useState(null);

  const [openSend, setOpenSend] = React.useState(false);

  const [data_html_mail, set_data_html_mail] = useState(null);

  const handleClickOpenSend = () => {
    setOpenSend(!openSend);
  };

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const sendEmail = (data) => {
    API_gg_send_email(data, (datax) => {
      console.log(datax);
    });
  };

  const handleClickOpenEdit = () => {
    setOpenEdit(!openEdit);
  };

  return (
    <Box
      style={{
        padding: 10,
        borderStyle: "solid",
        borderWidth: "thin",
        borderColor: "#B0BEC5",
      }}
    >
      {data_html_mail == null ? null : (
        <Dialog
          open={openSend}
          onClose={handleClickOpenSend}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography variant="h5">
              Subject: {data_html_mail.subject}
            </Typography>
            <Typography variant="h6">
              To email: {data_html_mail.to_email}
            </Typography>
            <Typography variant="h6">cc default: acc1sva@gmail.com</Typography>
            <Typography variant="h6">cc: {data_html_mail.mail_cc_1}</Typography>
            <Typography variant="h6">cc: {data_html_mail.mail_cc_2}</Typography>
            <Typography variant="h6">cc: {data_html_mail.mail_cc_3}</Typography>
          </DialogTitle>
          <DialogContent>
            <div>{Parser(data_html_mail.html_content)}</div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickOpenSend}>Close</Button>
            <Button
              onClick={() => {
                handleClickOpenSend();
                sendEmail(data_html_mail);
              }}
              autoFocus
            >
              Confirm Sent
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <DialogCus
        title="Edit operation"
        handleClickOpen={handleClickOpenEdit}
        open={openEdit}
      >
        <EditOper
          data_input={dataEdit}
          onSaveClick={(data) => {
            EditOperClick(data);
            handleClickOpenEdit();
          }}
          DeleteClick={() => {
            handleClickOpenEdit();
            DeleteClick(dataEdit._id);
          }}
        />
      </DialogCus>
      <Box
        style={{
          flexDirection: "row",
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          {SortByDay ? (
            <Typography variant="h5" component="div">
              Day {item.day_index + 1}
              {/* {item.day_index_detail} */}
            </Typography>
          ) : (
            <Typography variant="h5" component="div">
              {item.vendor_type}: {item.vendor_code}
            </Typography>
          )}
        </Box>
        <Box
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          {item.mail_sent != null ? (
            <Box
              style={{
                margin: 6,
                padding: 10,
                borderStyle: "solid",
                borderWidth: "thin",
                borderColor: "#B0BEC5",
                backgroundColor: sent_color,
              }}
              variant="outlined"
            >
              Sent email
            </Box>
          ) : null}
          {!SortByDay ? (
            <Button
              style={{ margin: 6 }}
              disabled={status == "confirmed"}
              variant="outlined"
              onClick={() => ConfirmClick(item.booking_id, item.vendor_id)}
            >
              Confirm
            </Button>
          ) : null}
        </Box>
      </Box>
      {item.data.map((item1, index1) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: item1.confirm ? "#E1F5FE" : "#FFEBEE",
              padding: 1,
              borderStyle: "solid",
              borderWidth: "thin",
              borderColor: "#B0BEC5",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
              }}
            >
              {SortByDay ? (
                <Typography variant="h6">
                  {item1.vendor_type}
                  {" - "}
                  {item1.vendor_code}
                  {" - "}Service: {item1.service_code}
                </Typography>
              ) : (
                <Typography variant="h6">
                  Day {item1.day_index + 1}
                  {" - "}
                  {/* {item1.day_index_detail}
                  {" - "} */}
                  Service: {item1.service_code}
                </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flex: 1,
                  // width: "150%",
                }}
              >
                <Typography>
                  double: {render_number(item1.price)} {item1.price_currency}
                </Typography>
                <Typography>
                  single: {render_number(item1.price_for_customer)}{" "}
                  {item1.price_for_customer_currency}
                </Typography>
              </Box>
            </Box>
            {item1.manual_insert ||
            item1.price_for_customer == 0 ||
            item1.price == 0 ? (
              <Button
                variant="outlined"
                style={{ margin: 6 }}
                onClick={() => {
                  handleClickOpenEdit();
                  setDataEdit(item1);
                }}
              >
                Edit price
              </Button>
            ) : null}
          </Box>
        );
      })}

      {item.vendor_type == "flight" ? null : SortByDay ? null : (
        <Box>
          <Button
            onClick={() => {
              handleClickOpen();
            }}
            style={{ margin: 6 }}
            variant="outlined"
          >
            View Email
          </Button>
          {open ? (
            <EditorCus
              operation_details={item}
              booking_details={booking_details}
              onSendEmailClick={(data_html) => {
                let data = {
                  booking_id: item.booking_id,
                  vendor_id: item.vendor_id,
                  to_email: item.data_vendor.email,
                  html_content: data_html,
                  subject:
                    booking_details.booking_number + " // " + item.vendor_code,
                  mail_cc_1: item.data_vendor.mail_cc_1,
                  mail_cc_2: item.data_vendor.mail_cc_2,
                  mail_cc_3: item.data_vendor.mail_cc_3,
                };

                handleClickOpenSend();
                set_data_html_mail(data);
              }}
            />
          ) : null}
        </Box>
      )}
    </Box>
  );
};
