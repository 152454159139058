import React, { useState, useEffect } from "react";
import {
  TextField,
  Typography,
  Box,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";

import dayjs from "dayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTasks, useTasksDispatch } from "../AppContext";
import { dispatchTYPE } from "../utils/constDispatch";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function InputLeftInfo({ props, booking_id }) {
  const data_pdf = useTasks();
  const dispatch = useTasksDispatch();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const base_url_website = "https://booking.svietnamadventures.com";

  const link_draft =
    base_url_website + "/booking/draft/" + booking_id + "_" + data_pdf.secret;
  const link_detail =
    base_url_website + "/booking/detail/" + booking_id + "_" + data_pdf.secret;

  const copyToClipboard = (text, type) => {
    try {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        showSnackbar(`${type} link copied to clipboard!`);
      } else {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            showSnackbar(`${type} link copied to clipboard!`);
          })
          .catch((err) => {
            console.error("Could not copy text: ", err);
            fallbackCopyTextToClipboard(text);
          });
      }
    } catch (err) {
      console.error("Clipboard error: ", err);
      showSnackbar("Failed to copy to clipboard", "error");
    }
  };

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      const msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text was " + msg);
      if (!successful) {
        showSnackbar("Failed to copy to clipboard", "error");
      }
    } catch (err) {
      console.error("Fallback: Could not copy text: ", err);
      showSnackbar("Failed to copy to clipboard", "error");
    }

    document.body.removeChild(textArea);
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Typography variant="h4" style={{ marginTop: 10, marginBottom: 10 }}>
        Link
      </Typography>

      <Typography>
        <Typography variant="h7">Draft booking: </Typography>
        <a href={link_draft} target="_blank" rel="noopener noreferrer">
          {link_draft.slice(0, 30)}...
        </a>
        <Button
          variant="outlined"
          size="small"
          startIcon={<ContentCopyIcon />}
          onClick={() => copyToClipboard(link_draft, "Draft")}
          sx={{ ml: 1 }}
        >
          Copy
        </Button>
      </Typography>
      <Typography>
        <Typography variant="h7">Detail booking: </Typography>
        <a href={link_detail} target="_blank" rel="noopener noreferrer">
          {link_detail.slice(0, 30)}...
        </a>
        <Button
          variant="outlined"
          size="small"
          startIcon={<ContentCopyIcon />}
          onClick={() => copyToClipboard(link_detail, "Detail")}
          sx={{ ml: 1 }}
        >
          Copy
        </Button>
      </Typography>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Typography variant="h4" style={{ marginTop: 10, marginBottom: 10 }}>
        Title
      </Typography>
      <Box style={{ flexDirection: "row", display: "flex" }}>
        <TextField
          style={{ margin: 5 }}
          id="draft_title_pdf"
          label="draft title"
          fullWidth
          variant="outlined"
          defaultValue={"TBA"}
          value={data_pdf.draft_title_pdf}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.draft_title_pdf,
              value: e.target.value,
            });
          }}
        />
        <TextField
          style={{ margin: 5 }}
          id="detail_title_pdf"
          label="detail title"
          fullWidth
          variant="outlined"
          defaultValue={"TBA"}
          value={data_pdf.detail_title_pdf}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.detail_title_pdf,
              value: e.target.value,
            });
          }}
        />
      </Box>
      <Typography variant="h4" style={{ marginTop: 10, marginBottom: 10 }}>
        Booking Infomation
      </Typography>
      <Box style={{ flexDirection: "row", display: "flex" }}>
        <TextField
          style={{ margin: 5 }}
          id="customer_email"
          label="customer email"
          variant="outlined"
          defaultValue={"TBA"}
          value={data_pdf.customer_email}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.customer_email,
              value: e.target.value,
            });
          }}
        />
        <TextField
          style={{ margin: 5 }}
          id="customer_name"
          label="Custom name"
          variant="outlined"
          value={data_pdf.customer_name}
          defaultValue={"TBA"}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.customer_name,
              value: e.target.value,
            });
          }}
        />
        <TextField
          style={{ margin: 5 }}
          id="customer_cell"
          label="customer cell"
          variant="outlined"
          defaultValue={"TBA"}
          value={data_pdf.customer_cell}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.customer_cell,
              value: e.target.value,
            });
          }}
        />
      </Box>
      <Box style={{ flexDirection: "row", display: "flex" }}>
        <TextField
          style={{ margin: 5 }}
          id="booking_number"
          label="Booking number"
          variant="outlined"
          defaultValue={"TBA"}
          value={data_pdf.booking_number}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.booking_number,
              value: e.target.value,
            });
          }}
        />
        <TextField
          style={{ margin: 5 }}
          id="room_type"
          label="Room type"
          variant="outlined"
          defaultValue={"TBA"}
          value={data_pdf.room_type}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.room_type,
              value: e.target.value,
            });
          }}
        />
        <TextField
          style={{ margin: 5 }}
          id="prepare_by"
          label="prepare by"
          variant="outlined"
          defaultValue={"TBA"}
          value={data_pdf.prepared_by}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.prepared_by,
              value: e.target.value,
            });
          }}
        />
      </Box>

      <Typography variant="h4" style={{ marginTop: 10, marginBottom: 10 }}>
        Booking Detail
      </Typography>
      <Box style={{ flexDirection: "row", display: "flex" }}>
        <TextField
          style={{ margin: 5 }}
          id="no_of_clients"
          label="no of clients"
          variant="outlined"
          defaultValue={"TBA"}
          value={data_pdf.no_of_client}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.no_of_client,
              value: e.target.value,
            });
          }}
        />
        <TextField
          style={{ margin: 5 }}
          id="check_in"
          label="check in"
          variant="outlined"
          defaultValue={"TBA"}
          value={data_pdf.check_in}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.check_in,
              value: e.target.value,
            });
          }}
        />
        <TextField
          style={{ margin: 5 }}
          id="check_out"
          label="check out"
          variant="outlined"
          defaultValue={"TBA"}
          value={data_pdf.check_out}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.check_out,
              value: e.target.value,
            });
          }}
        />
        <TextField
          style={{ margin: 5 }}
          id="special_food_requirements"
          label="special requirements"
          variant="outlined"
          defaultValue={"TBA"}
          value={data_pdf.special_food_requirements}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.special_food_requirements,
              value: e.target.value,
            });
          }}
        />
      </Box>
      <Box style={{ flexDirection: "row", display: "flex" }}>
        <TextField
          style={{ margin: 5 }}
          fullWidth
          id="arrival_ariport"
          label="arrival airport"
          variant="outlined"
          defaultValue={"TBA"}
          value={data_pdf.arrival_airport}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.arrival_airport,
              value: e.target.value,
            });
          }}
        />
        <TextField
          style={{ margin: 5 }}
          id="arrival_flight"
          label="arrival flight"
          variant="outlined"
          defaultValue={"TBA"}
          value={data_pdf.arrival_flight}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.arrival_flight,
              value: e.target.value,
            });
          }}
        />
      </Box>
      <Box style={{ flexDirection: "row", display: "flex" }}>
        <TextField
          fullWidth
          style={{ margin: 5 }}
          id="departure_airport"
          label="departure airport"
          variant="outlined"
          defaultValue={"TBA"}
          value={data_pdf.departure_airport}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.departure_airport,
              value: e.target.value,
            });
          }}
        />

        <TextField
          style={{ margin: 5 }}
          id="departure_flight"
          label="departure_flight"
          variant="outlined"
          defaultValue={"TBA"}
          value={data_pdf.departure_flight}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.departure_flight,
              value: e.target.value,
            });
          }}
        />
      </Box>
      <Box style={{ flexDirection: "row", display: "flex" }}>
        <TextField
          style={{ margin: 5 }}
          id="package"
          fullWidth
          label="package"
          variant="outlined"
          defaultValue={"TBA"}
          value={data_pdf.package}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.package,
              value: e.target.value,
            });
          }}
        />
      </Box>
      <Box style={{ flexDirection: "row", display: "flex" }}>
        <TextField
          style={{ margin: 5 }}
          id="tour_type"
          label="tour type"
          variant="outlined"
          defaultValue={"TBA"}
          value={data_pdf.tour_type}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.tour_type,
              value: e.target.value,
            });
          }}
        />
        <TextField
          style={{ margin: 5 }}
          id="tour_guide"
          fullWidth
          label="tour_guide"
          variant="outlined"
          defaultValue={"TBA"}
          value={data_pdf.tour_guide}
          onChange={(e) => {
            dispatch({
              type: dispatchTYPE.tour_guide,
              value: e.target.value,
            });
          }}
        />
      </Box>
      <Box style={{ marginTop: 20 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoItem>
            <DatePicker
              format="DD/MM/YYYY"
              value={dayjs(data_pdf.day_start, "DD/MM/YYYY")}
              label="day_start"
              onChange={(e) => {
                dispatch({
                  type: dispatchTYPE.day_start,
                  value: dayjs(e).format("DD/MM/YYYY"),
                });
              }}
            />
            <Button
              variant="outlined"
              onClick={() =>
                dispatch({ type: dispatchTYPE.day_start, value: null })
              }
            >
              CLEAR day start
            </Button>
          </DemoItem>
        </LocalizationProvider>
      </Box>
    </div>
  );
}
