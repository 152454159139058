import React from "react";
import { View, Text } from "@react-pdf/renderer";

const UserInfo = ({ children, ...props }) => (
  <View>
    <Text
      style={{
        marginTop: 10,
        fontSize: 11,
        textAlign: "justify",
        fontFamily: "Helvetica-Bold",
        lineHeight: "1.5px",
      }}
    >
      Custom names: {props.customer_name}
      {"\n"}
      Booking REF: {props.booking_number}
      {"\n"}
      Room Type: {props.room_type}
      {"\n"}
      Special requirements: {props.special_food_requirements}
    </Text>
    <Text
      style={{
        fontSize: 12,
        marginBottom: 10,
        textAlign: "justify",
        fontFamily: "Helvetica",
      }}
    >
      We are pleased to confirm your itinerary as follows:
    </Text>
  </View>
);

export { UserInfo };
