import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

export default function Navbar({ title, onGoBack, onSave }) {
  return (
    <AppBar position="sticky" sx={{ top: 0, zIndex: 1100 }}>
      <Toolbar>
        <IconButton onClick={onGoBack} sx={{ color: "white" }}>
          <ArrowBackIcon />
          <Typography sx={{ ml: 1 }}>Exit</Typography>
        </IconButton>

        <Typography
          variant="h6"
          component="div"
          sx={{ paddingLeft: 10, flexGrow: 1 }}
        >
          {title}
        </Typography>

        <IconButton
          sx={{
            color: "white",
            "& .MuiSvgIcon-root": { color: "white" },
            "& .MuiTypography-root": { color: "white" },
          }}
          onClick={onSave}
          color="white"
        >
          <SaveIcon />
          <Typography sx={{ ml: 1 }}>Save</Typography>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
