import dayjs from "dayjs";
import {
  checkStringNullOrEmpty,
  find_hotel_3_day,
  find_str_in_str,
  checkString,
  createNumberTypeRoom,
} from "../../utils/tool";

export const CreateContent = (operation_details, booking_details) => {
  let content_init = "";

  if (operation_details.vendor_type === "hotel") {
    let day_arr = [];
    let max_day = operation_details.data[0].day_index;
    let day_item = [operation_details.data[0].day_index];

    for (let i = 1; i < operation_details.data.length; i++) {
      let e = operation_details.data[i];

      if (e.day_index == max_day + 1) {
        day_item.push(e.day_index);
        max_day = e.day_index;
      } else {
        day_arr.push(day_item);
        day_item = [e.day_index];
        max_day = e.day_index;
      }
    }

    day_arr.push(day_item);
    console.log(day_arr);

    let hotel_call_html = ``;

    day_arr.map((itemx, indexx) => {
      let checkin_value = dayjs(booking_details.day_start, "DD/MM/YYYY")
        .add(itemx[0], "day")
        .format("DD-MMM-YYYY");
      let checkout_value = dayjs(booking_details.day_start, "DD/MM/YYYY")
        .add(itemx[itemx.length - 1] + 1, "day")
        .format("DD-MMM-YYYY");
      // hotel_call_html

      hotel_call_html =
        hotel_call_html +
        `<p>------------------------</p>
         <h4>Call ${indexx + 1}</h4>
         <p>Number and type of room: ${createNumberTypeRoom(
           booking_details.room_type,
           booking_details.activities[itemx[0]].accommondation.type
         )}</p>
         <p>Check-in: ${checkString(checkin_value)}</p>
         <p>Check-out: ${checkString(checkout_value)}</p>`;
    });

    let special_requirements = "";
    if (booking_details.special_food_requirements.includes("TBA")) {
      special_requirements = "";
    } else {
      special_requirements = ", " + booking_details.special_food_requirements;
    }

    content_init = `
      <h3>Dear ${
        checkString(operation_details.data_vendor.user_receive_email) == ""
          ? "team"
          : checkString(operation_details.data_vendor.user_receive_email)
      },</h3>
      <p>Please check and confirm the booking as below:</p>
      <p>------------------------</p>
      <h4>Code: ${checkString(booking_details.booking_number)}</h4>
      <p>Number of guests: ${checkString(booking_details.no_of_client)}</p>
      <p>Guest name: ${checkString(booking_details.customer_name)}</p>
      <p>Hotel: ${checkString(operation_details.data_vendor.name)}</p>
      <p>Special requirement: Nice room${special_requirements}</p>
      ${checkString(hotel_call_html)}`;
  }

  if (operation_details.vendor_type === "tour") {
    let tour_call_html = ``;
    operation_details.data.map((itemx, indexx) => {
      let travel_date = dayjs(booking_details.day_start, "DD/MM/YYYY")
        .add(itemx.day_index, "day")
        .format("DD-MMM-YYYY");

      tour_call_html =
        tour_call_html +
        `<p>------------------------</p>
         <h4>Tour name: ${checkString(itemx.service_code)}</h4>
         <p>Travel date: ${checkString(travel_date)}</p>
         <p>Pick up time: TBA</p>`;

      let day_pickup = itemx.day_index - 1;
      if (day_pickup < 0) day_pickup = 0;
      let day_dropoff = itemx.day_index;
      let acti_pickup = null;
      let acti_dropoff = null;

      try {
        acti_pickup = booking_details.activities[day_pickup].accommondation;
      } catch (error) {
        console.log(error);
      }

      if (acti_pickup != null) {
        tour_call_html += `<p>Pick up at: ${checkString(
          acti_pickup?.hotel
        )} ${checkString(acti_pickup?.address)}</p>`;
      }

      try {
        acti_dropoff = booking_details.activities[day_dropoff].accommondation;
      } catch (error) {
        console.log(error);
      }

      if (acti_dropoff != null) {
        tour_call_html += `<p>Drop off at: ${checkString(
          acti_dropoff?.hotel
        )} ${checkString(acti_dropoff?.address)}</p>`;
      } else {
        tour_call_html += `<p>Drop off at: </p>`;
      }
    });

    let special_requirements = "";
    if (booking_details.special_food_requirements.includes("TBA")) {
      special_requirements = "";
    } else {
      special_requirements = booking_details.special_food_requirements;
    }

    content_init = `
      <h3>Dear Team,</h3>
      <p>Please check and confirm the booking as below:</p>
      <p>Code: ${checkString(booking_details.booking_number)}</p>
      <p>Number of guests: ${checkString(booking_details.no_of_client)}</p>
      <p>Guest name: ${checkString(booking_details.customer_name)}</p>
      <p>Special requirement: ${special_requirements}</p>

      ${tour_call_html}`;
  }

  if (operation_details.vendor_type === "cruise") {
    let day_arr = [];
    let max_day = operation_details.data[0].day_index;
    let day_item = [operation_details.data[0].day_index];

    for (let i = 1; i < operation_details.data.length; i++) {
      let e = operation_details.data[i];

      if (e.day_index == max_day + 1) {
        day_item.push(e.day_index);
        max_day = e.day_index;
      } else {
        day_arr.push(day_item);
        day_item = [e.day_index];
        max_day = e.day_index;
      }
    }

    let tour_call_html = ``;

    day_arr.push(day_item);
    day_arr.map((itemx, indexx) => {
      let travel_date = dayjs(booking_details.day_start, "DD/MM/YYYY")
        .add(itemx[0], "day")
        .format("DD-MMM-YYYY");

      let travel_date_out = dayjs(booking_details.day_start, "DD/MM/YYYY")
        .add(itemx[itemx.length - 1] + 1, "day")
        .format("DD-MMM-YYYY");

      tour_call_html =
        tour_call_html +
        `<p>Number and type of room: ${createNumberTypeRoom(
          booking_details.room_type,
          booking_details.activities[itemx[0]].accommondation.type
        )}</p>
         <p>Check in: ${checkString(travel_date)}</p>
         <p>Check out: ${checkString(travel_date_out)}</p>`;

      let day_pickup = itemx[0] - 1;
      let day_dropoff = itemx[itemx.length - 1] + 1;
      let acti_pickup = null;
      let acti_dropoff = null;

      let pickuptime = null;

      try {
        acti_pickup = booking_details.activities[day_pickup].accommondation;
        pickuptime = booking_details.activities[day_pickup].pickuptime;
      } catch (error) {
        console.log(error);
      }

      if (acti_pickup != null) {
        // <p>Pick up time: ${checkString(pickuptime)}</p>
        tour_call_html += `
           <p>Pick up at: ${checkString(acti_pickup?.hotel)} ${checkString(
          acti_pickup?.address
        )}</p>`;
      }

      try {
        acti_dropoff = booking_details.activities[day_dropoff].accommondation;
      } catch (error) {
        console.log(error);
      }

      if (acti_dropoff != null) {
        tour_call_html += `
         <p>Drop off at: ${checkString(acti_dropoff?.hotel)} ${checkString(
          acti_dropoff?.address
        )}</p>`;
      } else {
        tour_call_html += `<p>Drop off at</p>`;
      }
    });

    let special_requirements = "";
    if (booking_details.special_food_requirements.includes("TBA")) {
      special_requirements = "";
    } else {
      special_requirements = ", " + booking_details.special_food_requirements;
    }

    content_init = `
      <h3>Dear ${checkString(
        operation_details.data_vendor.user_receive_email
      )},</h3>
      <p>Please check and confirm the booking as below:</p>
      <p>Code: ${checkString(booking_details.booking_number)}</p>
      <p>Number of guests: ${checkString(booking_details.no_of_client)}</p>
      <p>Guest name: ${checkString(booking_details.customer_name)}</p>
      <p>------------------------</p>
      <h4>Cruise: ${checkString(operation_details.data_vendor.name)}</h4>
      <p>Special requirement: Nice room, far from engine${special_requirements}</p>
      ${tour_call_html}`;
  }
  if (operation_details.vendor_type === "transportation") {
    let tour_call_html = ``;

    operation_details.data.map((itemx, indexx) => {
      let travel_date = dayjs(booking_details.day_start, "DD/MM/YYYY")
        .add(itemx.day_index, "day")
        .format("DD-MMM-YYYY");

      let pickup_place = "";
      let dropoff_place = "";

      let hotel_3_day = find_hotel_3_day(booking_details, itemx.day_index);

      if (checkStringNullOrEmpty(itemx.pickup_place)) {
        if (find_str_in_str(itemx.service_code, "tien")) {
          pickup_place = hotel_3_day.hotel_yesterday;
        }
      } else {
        pickup_place = itemx.pickup_place;
      }

      if (checkStringNullOrEmpty(itemx.dropoff_place)) {
        if (find_str_in_str(itemx.service_code, "don")) {
          dropoff_place = hotel_3_day.hotel_this_day;
        }
      } else {
        dropoff_place = itemx.dropoff_place;
      }

      let show_flight_no = "";
      if (
        find_str_in_str(itemx.service_code, "don sb") ||
        find_str_in_str(itemx.service_code, "tien sb")
      ) {
        show_flight_no = `<p>Flight No.: </p>`;
      }

      tour_call_html =
        tour_call_html +
        `<p>------------------------</p>
         <h4>Pick up date & time: ${checkString(travel_date)}</h4>
         ${checkString(show_flight_no)}
         <p>Pick up place: ${checkString(pickup_place)}</p>
         <p>Drop off: ${checkString(dropoff_place)}</p>`;
    });

    content_init = `
      <h3>Dear ${checkString(
        operation_details.data_vendor.user_receive_email
      )},</h3>
      <p>I would like to book pick up service as below:</p>
      <p>------------------------</p>
      <h4>Code: ${checkString(booking_details.booking_number)}</h4>
      <p>Number of guests: ${checkString(booking_details.no_of_client)}</p>
      <p>Guest name: ${checkString(booking_details.customer_name)}</p>
      ${tour_call_html}`;
  }

  content_init =
    content_init +
    `<p>------------------------</p>
    <p>Best regards,</p>
    <h4>Uyen Tran</h4>
    <p>Purchasing Department</p>
    <p>Mobile: 084 579 4384</p>
    <p>Mail: purchasing@svietnamadventures.com</p>`;

  return content_init;
};
