import React from "react";
import { PDFViewer } from "@react-pdf/renderer";

import DocumentDetail from "./DocumentDetail";

export default function TabPDFDetail({ data_pdf }) {
  if (data_pdf == null) {
    return null;
  } else {
    return (
      <PDFViewer style={{ flex: 1 }}>
        <DocumentDetail data_pdf={data_pdf} />
      </PDFViewer>
    );
  }
}
