import React from "react";
import { Document, Page, Text } from "@react-pdf/renderer";

import { styles } from "./AppStyle";
import { TableRow, TableHeader, TableTourName, FirstDeposit } from "./TableCom";
import {
  FooterLine,
  HeaderCus,
  FooterLeft,
  FooterRight,
  FooterPageNum,
} from "./HeaderFooter";
import { UserInfo } from "./UserInfo";
import { gen_day } from "../../utils/dayshow";

export default function DocumentDraft({ data_pdf }) {
  if (data_pdf == null) {
    return null;
  } else {
    const day_showing = gen_day(data_pdf.day_start, data_pdf.activities.length);
    const title_pdf = data_pdf.booking_number + ".pdf";

    return (
      <Document title={title_pdf}>
        <Page style={styles.body} wrap>
          <HeaderCus />
          <Text style={styles.title}>{data_pdf.draft_title_pdf}</Text>

          <UserInfo
            customer_name={data_pdf.customer_name}
            booking_number={data_pdf.booking_number}
            room_type={data_pdf.room_type}
            special_food_requirements={data_pdf.special_food_requirements}
          />

          <TableHeader />
          {data_pdf.activities.map((item, index) => (
            <TableRow
              key={index}
              data={item}
              index={index}
              day={
                data_pdf.day_start == null
                  ? day_showing[index].day
                  : day_showing[index].date
              }
            />
          ))}

          <TableTourName data={data_pdf.price} />
          {data_pdf.price.slice(2).map((item, index) => (
            <FirstDeposit
              key={index}
              label={item.label}
              value={item.value}
              price={item.price}
            />
          ))}
          <Text style={styles.notetext}>
            Note: Hotels are subject to change and may be replaced by the same
            standard and quality.
          </Text>

          <FooterLine />
          <FooterLeft />
          <FooterPageNum />
          <FooterRight />
        </Page>
      </Document>
    );
  }
}
