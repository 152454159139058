import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Box,
  DialogContent,
  LinearProgress,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CopyAllRoundedIcon from "@mui/icons-material/CopyAllRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

import {
  API_get_accommondation,
  API_edit_accommondation,
  API_clone_accommondation,
  API_delete_accommondation,
} from "./utils/http";
import AccomInput from "./components/AccomInput";
import AppBarSearchBox from "./components/SearchBox";

import { COLUMN_ACCOM } from "./utils/constDispatch";
import { AlertCus } from "./components/AlertCus";
import { DialogCus } from "./components/DialogCus";
import { checkOper_or_admin } from "./utils/tool";

export default function Accommodation() {
  const [density, setDensity] = React.useState("compact");
  const [search_text, set_search_text] = useState("");
  const [row_click, set_row_click] = useState(null);
  const [accommondation, set_accommondation] = useState(null);
  const [edit_ok, set_edit_ok] = useState(null);
  const [clone_ok, set_clone_ok] = useState(null);
  const [delete_ok, set_delete_ok] = useState(null);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [data_filter, set_data_filter] = useState(null);
  const [new_code, set_new_code] = useState(null);

  const handle_search = (search_text) => {
    if (search_text.replace(/\W/g, "").toLocaleLowerCase() == "") {
      set_data_filter(accommondation);
    } else {
      let data_tmp = accommondation.filter((x) => {
        let str = x.city + x.code + x.hotel;

        str = str.replace(/\W/g, "").toLocaleLowerCase();
        let str_search = search_text.replace(/\W/g, "").toLocaleLowerCase();
        return str.includes(str_search);
      });

      set_data_filter(data_tmp);
    }
  };

  async function get_data_accommondation() {
    API_get_accommondation((x) => {
      if (x.success) {
        let data = x.data;
        set_accommondation(data);
        set_data_filter(data);
      }
    });
  }
  const handleClickOpen = () => {
    setOpen(!open);
  };
  const handleClickOpen1 = () => {
    setOpen1(!open1);
  };
  const handleClickOpen2 = () => {
    setOpen2(!open2);
  };

  async function edit_data_accommondation(id, data) {
    API_edit_accommondation(id, data, (data) => {
      set_edit_ok(data.success);
      handleClickOpen();
      get_data_accommondation();
    });
  }

  useEffect(() => {
    get_data_accommondation();
  }, []);

  const [selected_id, set_selected_id] = useState(null);
  const [rowSelection, setRowSelection] = useState([]);

  const confirmClone = () => {
    handleClickOpen2();
    API_clone_accommondation(selected_id, new_code, (e) => {
      set_clone_ok(e.success);
      if (e.success) {
        get_data_accommondation();
      }
    });
  };

  const confirmDelete = () => {
    handleClickOpen1();
    API_delete_accommondation(selected_id, (x) => {
      set_delete_ok(x.success);
      if (x.success) {
        get_data_accommondation();
      }
    });
  };

  return (
    <Box style={{ flexDirection: "column", display: "flex" }}>
      <AppBarSearchBox
        title="Accomodation"
        value={search_text}
        onChange={(e) => {
          if (e.target.value == "") {
            handle_search("");
            set_selected_id(null);
          }
          set_search_text(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handle_search(search_text);
            set_selected_id(null);
          }
        }}
      />

      <AlertCus condition={edit_ok} message="Edit" />
      <AlertCus condition={clone_ok} message="Clone" />
      <AlertCus condition={delete_ok} message="Delete" />

      {checkOper_or_admin() ? (
        <Box
          style={{
            display: "flex",
            padding: 20,
            justifyContent: "flex-end",
          }}
        >
          <Button
            disabled={selected_id == null}
            style={{ marginLeft: 20 }}
            variant="outlined"
            endIcon={<CopyAllRoundedIcon />}
            onClick={() => handleClickOpen2()}
          >
            Clone
          </Button>
          <Button
            variant="outlined"
            disabled={selected_id == null}
            color="error"
            style={{ marginLeft: 20 }}
            endIcon={<DeleteRoundedIcon />}
            onClick={() => handleClickOpen1()}
          >
            Delete
          </Button>
        </Box>
      ) : null}

      <DialogCus
        title="Edit accommondation"
        handleClickOpen={handleClickOpen}
        open={open}
      >
        <AccomInput
          onHideClick={(id) => {
            edit_data_accommondation(id, { hide: true });
            handleClickOpen();
          }}
          onShowClick={(id) => {
            edit_data_accommondation(id, { hide: false });
            handleClickOpen();
          }}
          init_data={row_click}
          onSaveClick={(id, data) => {
            edit_data_accommondation(id, data);
          }}
        />
      </DialogCus>

      <DialogCus
        open={open2}
        title="Clone"
        handleClickOpen={handleClickOpen2}
        handleClickConfirm={confirmClone}
      >
        <DialogContent>
          <TextField
            placeholder="Enter new code"
            autoFocus
            value={new_code}
            onChange={(e) => set_new_code(e.target.value)}
            margin="dense"
            id="code"
            label="code"
            fullWidth
            variant="standard"
          />
        </DialogContent>
      </DialogCus>

      <DialogCus
        open={open1}
        title="Confirm delete"
        handleClickOpen={handleClickOpen1}
        handleClickConfirm={confirmDelete}
      ></DialogCus>

      {accommondation == null ? (
        <Box sx={{ width: "100%", marginTop: 10 }}>
          <LinearProgress />
        </Box>
      ) : (
        <Box
          sx={{ height: "100%", width: "90%", paddingLeft: 5, paddingRight: 5 }}
        >
          <DataGrid
            slots={{ toolbar: GridToolbar }}
            density={density}
            onDensityChange={(newDensity) => {
              setDensity(newDensity);
            }}
            getRowId={(item) => item._id}
            rows={data_filter}
            columns={COLUMN_ACCOM}
            initialState={{ pagination: { paginationModel: { pageSize: 20 } } }}
            pageSizeOptions={[5, 10, 20]}
            rowSelectionModel={rowSelection}
            onRowSelectionModelChange={(e) => {
              setRowSelection(e);
              set_selected_id(e[0]);
              set_row_click(accommondation.find((x) => x._id === e[0]));
            }}
            disableMultipleRowSelection={true}
            onCellDoubleClick={() => {
              if (checkOper_or_admin()) {
                handleClickOpen();
              }
            }}
          />
        </Box>
      )}
    </Box>
  );
}
